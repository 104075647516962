import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Lean Learn Lab Is Under Construction.
        </p>
        <p>
          Easy Thing, Clear Way.
        </p>

        <a
          className="App-link"
          href="https://www.youtube.com/@leanlearnlab"
          target="_blank"
          rel="noopener noreferrer"
        >
          View Youtube
        </a>
        <a
            className="App-link"
            href="https://twitter.com/leanlearnlab"
            target="_blank"
            rel="noopener noreferrer"
        >
          Follow Twitter
        </a>
        <a
            className="App-link"
            href="https://www.youtube.com/@leanlearnlab"
            target="_blank"
            rel="noopener noreferrer"
        >
          Subscribe Newsletter
        </a>
      </header>
    </div>
  );
}

export default App;
